import { Component, OnInit, ElementRef } from '@angular/core';
// import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { AccountService } from '@app/services/account.service';
import { AlertService } from '@app/services/alert.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserAccess } from '@app/model/user-access.model';
import { APIService } from '@app/services/api.service';
import { NbToastrService } from '@nebular/theme';
import { NotificationList } from '@app/model/notification.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public name = '';
  public isCollapsed = true;
  public focus;
  public listTitles: any[];
  public location: Location;
  public isLogin = false;
  notiinfo: Array<NotificationList> = [];

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    public accountService: AccountService,
    private apiService: APIService,
    private nbToastr: NbToastrService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,

  ) {
    this.location = location;

    if (this.accountService.userValue?.Id) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }

    //console.log("Login status : " + this.isLogin);
    this.GetNoti();

  }

  ngOnInit() {

    //console.log(this.accountService.userValue);
    if (this.isLogin) {
      this.name = this.accountService.userValue.FirstName + ' ' + this.accountService.userValue.LastName;
      // this.getNotification();
    }
    // this.listTitles = ROUTES.filter(listTitle => listTitle);


  }

  GetNoti() {
    this.notiinfo = [];
    this.apiService.GetNotification(this.accountService.userValue.Id).subscribe(async data => {
      if (data['data'] !== null) {
        if (data['data'].length < 5) {
          for (let i = 0; i < data['data'].length; i++) {
            if (data['data'][i]['Path'] === '') {
              this.notiinfo.push(data['data'][i]);
            } else {
              const ref = this.storage.ref(data['data'][i]['Path'] as string);
              let img = await ref.getDownloadURL().toPromise();
              data['data'][i]['Path'] = img as string;
              this.notiinfo.push(data['data'][i]);
            }
          }
        } else if (data['data'].length > 5) {
          for (let i = 0; i < 5; i++) {
            if (data['data'][i]['Path'] === '') {
              this.notiinfo.push(data['data'][i]);
            } else {
              const ref = this.storage.ref(data['data'][i]['Path'] as string);
              let img = await ref.getDownloadURL().toPromise();
              data['data'][i]['Path'] = img as string;
              this.notiinfo.push(data['data'][i]);
            }
          }
        }
      } else {
        this.notiinfo = [];
      }

    }, error => {
    })
  }


  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  wineDetail(id, v, cid, bt) {
    this.router.navigate(['/wine-detail'], { queryParams: { id: id, v: v, cid: cid, bt: bt } });
  }
  signOut() {
    this.isLogin = false;
    this.accountService.logout();
  }

  isAdmin() {
    if (this.accountService.userValue === null) {
      return false;
    } else {
      if (this.accountService.userValue.UserTypeId === 1) {
        return true;
      } else if (this.accountService.userValue.UserTypeId === 2) {
        return false;
      } else {
        return false;
      }
    }
  }
}
