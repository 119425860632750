import { Component, OnInit, ElementRef } from '@angular/core';
// import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { AccountService } from '@app/services/account.service';
import { AlertService } from '@app/services/alert.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserAccess } from '@app/model/user-access.model';
import { APIService } from '@app/services/api.service';
import { NbToastrService } from '@nebular/theme';
import { NotificationList } from '@app/model/notification.model';
import liff from '@line/liff';

@Component({
  selector: 'app-line-navbar',
  templateUrl: './line-navbar.component.html',
  styleUrls: ['./line-navbar.component.scss']
})
export class LineNavbarComponent implements OnInit {
  public name = '';
  public isCollapsed = true;
  public focus;
  public listTitles: any[];
  public location: Location;
  public isLogin = false;

  public notiList: Array<NotificationList> = new Array();

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    public accountService: AccountService,
    private apiService: APIService,
    private nbToastr: NbToastrService,
    private alertService: AlertService) {
    this.location = location;

    if (this.accountService.userValue?.Id) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }

    //console.log("Login status : " + this.isLogin);

  }

  ngOnInit() {

    //console.log(this.accountService.userValue);
    if (this.isLogin) {
      this.name = this.accountService.userValue.FirstName + ' ' + this.accountService.userValue.LastName;
      // this.getNotification();
    }
    // this.listTitles = ROUTES.filter(listTitle => listTitle);


  }

  getDisplayName(): string {
    if (this.isLogin) {
      return this.accountService.userValue.FirstName + ' ' + this.accountService.userValue.LastName;
    } else {
      return 'Unkown Display Name';
    }
  }


  openDashboardPage() {
    // liff.openWindow({url: 'https://liff.line.me/1656910406-l8MQQOrB'});
    // liff.closeWindow();
  }


  openSearchPage() {
    liff.openWindow({url: 'https://liff.line.me/1656910406-l8MQQOrB'});
    liff.closeWindow();
  }

  openProfilePage() {
    liff.openWindow({url: 'https://liff.line.me/1656910406-oRrNNY92'});
    liff.closeWindow();
  }

  openNotificationPage() {
    liff.openWindow({url: 'https://liff.line.me/1656910406-dY3rrD8n'});
    liff.closeWindow();
  }

  openFavoritePage() {
    liff.openWindow({url: 'https://liff.line.me/1656910406-RqoaaM8g'});
    liff.closeWindow();
  }

  getNotification() {
    // this.apiService.getNotification(this.accountService.userValue.Id).subscribe(res => {
    //   Object.assign(this.notiList, res['data']);

    //   if (this.router.url.match('/home') || this.router.url.match('/officer-home')) {
    //     this.notiList.forEach(data => {
    //       this.nbToastr.info(data.Message, 'ระบบแจ้งเตือน', { duration: 7000, icon: 'bell-outline' });
    //     });
    //   }

    // }, error => {
    //   this.notiList = new Array();
    // })
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  signOut() {
    this.isLogin = false;
    this.accountService.logout();
  }

  isAdmin() {
    if (this.accountService.userValue === null) {
      return false;
    } else {
      if (this.accountService.userValue.UserTypeId === 1) {
        return true;
      } else if (this.accountService.userValue.UserTypeId === 2) {
        return false;
      } else {
        return false;
      }
    }
  }
}
