// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  webapiurl: (window as any)["envconfig"]["apiurl"] || "default",
  firebase: {
    apiKey: "AIzaSyAjjsTguXSVR3AQQBVcA9Ot9PyUDfCbf8c",
    authDomain: "tbit-excise.firebaseapp.com",
    projectId: "tbit-excise",
    storageBucket: "tbit-excise.appspot.com",
    messagingSenderId: "515250405290",
    appId: "1:515250405290:web:573bff86341300f7d4f5d3",
  },
  ws: {
    uid: "tstest",
    salt: "testest22"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
