import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-officer-layout',
  templateUrl: './officer-layout.component.html',
  styleUrls: ['./officer-layout.component.scss']
})
export class OfficerLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
      //// console.log("Scroll -> " + event.target.scrollTop);
  }

}
