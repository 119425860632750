import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { OfficerLayoutComponent } from './layouts/officer-layout/officer-layout.component';
import { ThaiDatePipe } from './helpers/thaidate.pipe';
import { LineLayoutComponent } from './layouts/line-layout/line-layout.component';

const routes: Routes =[
  {
    path: '',
    redirectTo: 'wine-search',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
      }
    ]
  }, {
    path: '',
    component: OfficerLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/officer-layout/officer-layout.module#OfficerLayoutModule'
      }
    ]
  }, {
    path: '',
    component: LineLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/line-layout/line-layout.module#LineLayoutModule'
      }
    ]
  }, {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/auth-layout/auth-layout.module#AuthLayoutModule'
      }
    ]
  }, {
    path: '**',
    redirectTo: 'wine-search'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
