import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "thaidate"
})
export class ThaiDatePipe implements PipeTransform {
  constructor(private datepipe: DatePipe) {}

  transform(date: string, format: string): string {
    let ThaiDay = [
      "อาทิตย์",
      "จันทร์",
      "อังคาร",
      "พุธ",
      "พฤหัสบดี",
      "ศุกร์",
      "เสาร์"
    ];
    let shortThaiMonth = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค."
    ];
    let longThaiMonth = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม"
    ];

    //console.log(date);

    let latest_date = this.datepipe.transform(date, "MM-dd-yyyy HH:mm:ss", '+0000');

    //console.log(latest_date);

    let localDate = new Date(latest_date + " UTC").toISOString();
    let inputDate = new Date(localDate);

    //console.log(inputDate);

    //console.log(inputDate.getHours());
    //console.log(inputDate.getMinutes());

    let dataDate = [
      inputDate.getDay(),
      inputDate.getDate(),
      inputDate.getMonth(),
      (inputDate.getFullYear()+543),
      inputDate.getHours() >= 10 ? inputDate.getHours() : ('0' + inputDate.getHours()),
      inputDate.getMinutes() >= 10 ? inputDate.getMinutes() : ('0' + inputDate.getMinutes())
    ];
    let outputDateFull = [
      "วัน " + ThaiDay[dataDate[0]],
      "ที่ " + dataDate[1],
      "เดือน " + longThaiMonth[dataDate[2]],
      "พ.ศ. " + dataDate[3]
    ];
    let outputDateShort = [
      ThaiDay[dataDate[0]],
      dataDate[1],
      shortThaiMonth[dataDate[2]],
      dataDate[3],
      dataDate[4] + ":" + dataDate[5]
    ];
    let outputDateMedium = [
      dataDate[1],
      longThaiMonth[dataDate[2]],
      dataDate[3]
    ];
    let outputDateTime = [
      dataDate[1],
      longThaiMonth[dataDate[2]],
      dataDate[3],
      " เวลา " + dataDate[4] + ":" + dataDate[5]
    ];
    let outputTime = [
      " เวลา " + dataDate[4] + ":" + dataDate[5]
    ];
    let returnDate: string;
    returnDate = outputDateMedium.join(" ");
    if (format == "full") {
      returnDate = outputDateFull.join(" ");
    }
    if (format == "medium") {
      returnDate = outputDateMedium.join(" ");
    }
    if (format == "short") {
      returnDate = outputDateShort.join(" ");
    }
    if (format == "datetime") {
      returnDate = outputDateTime.join(" ");
    }
    if (format == "time") {
      returnDate = outputTime.join(" ");
    }
    return returnDate;
  }
}
