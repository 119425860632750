import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AngularFireFunctions } from '@angular/fire/functions';
import { User } from '@app/model/user.model';
import { AccountService } from './account.service';
import { encryptStorage } from '@app/helpers/encrypt-storage';
import { Explain } from '@app/model/explain.model';
import { SignUpModel } from '@app/model/signup.model';
import { Utility } from '@app/helpers/utility';
import { WSImageClassificationAPIResponse } from '@app/model/wine-searcher-image-class.model';
import { WSImageAPIResponse } from '@app/model/wine-searcher-image-class.model';

@Injectable({ providedIn: 'root' })
export class APIService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private fns: AngularFireFunctions,
    private accountService: AccountService,
  ) {
  }


  //master
  MasterCountryList() {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/master/countryregionfilter');
  }

  MasterCountryYear() {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/master/countryfilter');
  }

  MasterEnumList(data) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/master/enumlist?enumgroup=' + data);
  }
  //user
  GetListUser() {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/user/getlist');
  }

  GetUserInfo(uid, email) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/user/getinfo?uid=' + uid + '&email=' + email);
  }

  SaveUserProfile(data) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/user/updateprofile', data);
  }

  //wine
  SaveFavorite(data) {
    let elm = {
      "WineId": Number(data.WineId),
      "Vintage": data.Vintage,
      "UserId": Number(data.CreatedBy),
      "Bottlesize": data.Bottlesize
    }
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-addFavorite', elm);
    // return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/wine/addfavorite', data);
  }



  GetFavorite(uid) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/wine/getfavorite?uid=' + uid);
  }


  DeleteFavorite(id, uid, vintage, bt) {
    return this.http.delete('https://asia-southeast1-tbit-excise.cloudfunctions.net/wine/deletefavorite?id=' + id + '&uid=' + uid + '&vintage=' + vintage + '&Bottlesize=' + bt);
  }

  GetWineSearch(Winename: string, Vintage: string, Location: string, AVB: number, BottleSize: string, Currencycode: string, uid: string, BottleCode: string) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/wine/WineSearch?Winename=' + Winename + '&Vintage=' + Vintage + '&Location=' + Location + '&AVB=' + AVB + '&BottleSize='
      + BottleSize + '&Currencycode=' + Currencycode + '&uid=' + uid + '&BottleCode=' + BottleCode);
  }

  GetWineProfile(wid, vintage, bt) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/wine/getwineprofile?wid=' + wid + '&vintage=' + vintage + '&bt=' + bt);
  }

  GetWineProfileList(winename, country, region, color, bottlesize, vintage, sdat, edat) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/wine/getwineprofilelist?winename=' + winename + '&country=' + country + '&region='
      + region + '&color=' + color + '&bottlesize=' + bottlesize + '&vintage=' + vintage + '&sdat=' + sdat + '&edat=' + edat);
  }

  GetWineAutoComplete(auto) {
    let wine_name = auto.replace(/[`~!@#$%^*_|+\=?;:".<>\{\}\[\]\\\/]/gi, '') || "";
    let wine_name_encode = new Utility().encodeRFC5987ValueChars(wine_name);
    wine_name = wine_name_encode;
    //console.log(wine_name);

    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/getAutocomplete?s=' + wine_name);
  }

  GetWineMerchant(data) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/wine/getmerchant', data);
  }

  //dashboard
  GetDateTime(sdate, edate) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/dashboard/getdate?sdate=' + sdate + '&edate=' + edate)
  }

  GetDashboardSum(winecolor, country, vintage, startdate, enddate) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/dashboard/getdashboardsummary?winecolor='
      + winecolor + '&country=' + country + '&vintage=' + vintage + '&startdate=' + startdate + '&enddate=' + enddate)
  }

  //notification
  GetNotification(uid) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/notification/getnotification?uid=' + uid)
  }

  //logging
  SaveLogging(data) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/accesslog/logging', data)
  }

  GetLogging(mail, sdate, edate) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/accesslog/logginglist?t=' + mail + '&sd=' + sdate + '&ed=' + edate)
  }


  //officer manage
  GetOfficerUserGroup() {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/usergroup/getlist')
  }

  GetOfficerUserGroupInfo(id) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/usergroup/getinfo?gid=' + id)
  }

  SaveOfficerUserGroupInfo(data) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/usergroup/save', data)
  }

  GetOfficerUserInGroup(id) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/usergroup/getuseringroup?gid=' + id)
  }

  GetOfficerUserToAssign(id) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/usergroup/getusertoassign?gid=' + id)
  }

  SaveOfficerUserInGroup(data) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/usergroup/saveusertogroup', data)
  }

  DeleteOfficerUserInGroup(uid, gid) {
    return this.http.delete('https://asia-southeast1-tbit-excise.cloudfunctions.net/usergroup/deleteuseringroup?uid=' + uid + '&gid=' + gid)
  }

  DeleteOfficerGroup(updateby, gid) {
    return this.http.delete('https://asia-southeast1-tbit-excise.cloudfunctions.net/usergroup/deletegroup?updateby=' + updateby + '&gid=' + gid)
  }

  SaveOfficerPermission(data) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/system/saveassign', data)
  }

  GetOfficerPermission(id) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/system/getuserassign?gid=' + id)
  }

  //report
  GetWineReport1(winename, country, region, color, bottlesize, vintage, sdat, edat, exp) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/report/getreport1?winename=' + winename + '&country=' + country + '&region='
      + region + '&color=' + color + '&bottlesize=' + bottlesize + '&vintage=' + vintage + '&sdat=' + sdat + '&edat=' + edat + '&reporttype=' + exp);
  }

  GetWineReport2(winename, country, region, color, bottlesize, vintage, sdat, edat, exp) {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/report/getreport2?winename=' + winename + '&country=' + country + '&region='
      + region + '&color=' + color + '&bottlesize=' + bottlesize + '&vintage=' + vintage + '&sdat=' + sdat + '&edat=' + edat + '&reporttype=' + exp);
  }


  detectDocumentText(data) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/ocr/detectDocumentText', data);
  }

  searchWineImage(data) {
    return this.http.post<WSImageClassificationAPIResponse>('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-searchImageByWS', data);
  }

  searchWineImage_SiteUAT(data) {
    return this.http.post<WSImageAPIResponse>('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-searchImageByWS', data);
  }


  //save wine meta data
  SaveWineMaster_Info(data) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-addMasterData', data);
  }

  GetWineMasterList() {
    return this.http.get(' https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-getMasterData')
  }

  GetWineMaster_Info(data) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-getMasterDataById', data);
  }

  EditWineMaster_Info(data) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-setMasterDataById', data);
  }

  DeleteWineInfo(WineId) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-deleteMasterDataById', WineId);
  }

  GetWineMasterCountryRegion() {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-getCountryRegionMaster')
  }


  SaveWinePriceInfo(WineId) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-addPriceSourceData', WineId);
  }

  // wine get year by name
  getWineVintageByname(winename) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-getWineVintageByname', winename);
  }

  getThaimerchant(winename) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-getThaimerchant', winename);
  }






}
