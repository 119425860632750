import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { OfficerLayoutComponent } from './layouts/officer-layout/officer-layout.component'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { CookieServiceModule, CookieConfig } from 'cookie-service-banner';

import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';

import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';

import { AlertComponent } from '@app/components/alert/alert.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';

import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbDialogModule, NbThemeModule, NbIconModule, NbGlobalPhysicalPosition, NbGlobalPositionStrategy, NbPosition, NbToastrModule } from '@nebular/theme';
import { ToastrModule } from 'ngx-toastr';
import { FileInputValueAccessor } from './helpers/file-input.accessor';
import { APIBackendInterceptor, apiBackendProvider } from './helpers/api-backend';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { ThaiDatePipe } from "./helpers/thaidate.pipe";
import { DatePipe } from "@angular/common";
import { NgxLoadingModule } from 'ngx-loading';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ThaiDatePipeModule } from './helpers/thaidate.module';

import { MomentModule } from 'ngx-moment';
import { LineLayoutRoutes } from './layouts/line-layout/line-layout.routing';
import { LineLayoutComponent } from './layouts/line-layout/line-layout.component';

const consentConfig: CookieConfig = { header: { title: "Cookie Consent Banner", message: "เว็ปไซต์นี้มีการใช้คุกกี้เพื่อปรับการใช้งานให้เหมาะกับคุณ วิเคราะห์การเข้าใช้เว็บไซต์ และเพื่อปรับปรุงประสบการณ์การใช้งานของคุณ เมื่อท่านกด*ยอมรับ*หรือยังคงเข้าชมเว็บไซต์ต่อ ถือว่าท่านยินยอมในการใช้งานคุกกี้ของเว็บไซต์นี้ ตรวจสอบนโยบายความเป็นส่วนตัวของเราได้. ", domain: "localhost", ga_id: "UA-123456-1", color: '#fff', bcolor: '#000000AA' }, acceptButton: { enable: false, accept: "Got it!", color: '#fff', bcolor: '#266433' }, allowButton: { enable: true, allow: "ยอมรับ", color: '#000', bcolor: '#f36e15f5' }, declineButton: { enable: true, deny: "ปฏิเสธ", color: '#000', bcolor: '#fff' }, learnMoreLink: { enable: true, learnMore: "นโยบายคุ้มครองข้อมูลส่วนบุคคล", link: "https://www.excise.go.th/cs/groups/public/documents/document/dwnt/ndi0/~edisp/uatucm424357.pdf", color: '#F05924' }, review: { enable: false, message: "Review My consentement", color: "", bcolor: "", } }

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    CookieServiceModule,
    CookieServiceModule.forRoot(consentConfig),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    NbDialogModule.forRoot(),
    NbThemeModule.forRoot({ name: 'default' }),
    NbToastrModule.forRoot({ position: NbGlobalPhysicalPosition.BOTTOM_RIGHT, destroyByClick: true }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      progressBar: true,
      progressAnimation: "decreasing",
      closeButton: true,
      enableHtml: true,
    }),
    NbIconModule,
    NbEvaIconsModule,
    NgxLoadingModule.forRoot({}),
    NgxSpinnerModule,
    ThaiDatePipeModule,
    MomentModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LineLayoutComponent,
    OfficerLayoutComponent,
    AlertComponent,
    FileInputValueAccessor,
  ],
  providers: [
    Location, { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    apiBackendProvider,
    ScreenTrackingService,
    UserTrackingService,
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



