export class User {
  Id: number = 0;
  Email: string = '';
  Password: string = '';
  AccountTypeId: number = 0;
  TitleId: number = 0;
  FirstName: string = '';
  LastName: string = '';
  IDCard: string = '';
  Passport: string = '';
  DOB: string = new Date().toISOString();
  Phone: string = '';
  Mobile: string = '';
  UserTypeId: number = 0;
  IsActive: boolean = true;
  CreatedDate: string = new Date().toISOString();
  Createjsony: number = 0;
  UpdatedDate: string = new Date().toISOString();
  Updatejsony: number = 0;
  LocalId: string = '';
  CustomToken: string = '';
  Token: string = '';
}
