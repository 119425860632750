import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AngularFireFunctions } from '@angular/fire/functions';
import { User } from '@app/model/user.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserAccess } from '@app/model/user-access.model';
import { encryptStorage } from '@app/helpers/encrypt-storage';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  private userAccessSubject: BehaviorSubject<Array<UserAccess>>;
  public userAccess: Observable<Array<UserAccess>>;
  private testUserID = [7, 12, 16, 17, 76, 224, 273, 484, 487, 713, 718, 884, 887, 932];
  private hideProjectID = [];

  constructor(
    private router: Router,
    private http: HttpClient,
    private fns: AngularFireFunctions,
    public auth: AngularFireAuth,

  ) {
    let user: User = encryptStorage?.getItem('user') ? encryptStorage.getItem('user') : '{}';
    this.userSubject = new BehaviorSubject<User>(user);
    this.user = this.userSubject.asObservable();

    this.userAccessSubject = new BehaviorSubject<Array<UserAccess>>(JSON.parse('[]'));
    this.userAccess = this.userAccessSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  public setToken(token) {
    let user = encryptStorage.getItem('user');
    user['Token'] = token;
    encryptStorage.setItem('user', JSON.stringify(user));
    this.userSubject.next(user);
  }

  getTestUsers(): number[] {
    return this.testUserID;
  }

  getHideProjects(): number[] {
    return this.hideProjectID;
  }

  login(username, password, ch) {
    let req = { email: username, password: password, ch: ch };
    return this.http.post<User>('https://asia-southeast1-tbit-excise.cloudfunctions.net/auth/signin', req)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        encryptStorage.setItem('user', JSON.stringify(user['data']));
        this.userSubject.next(user['data']);

        this.auth.signInWithEmailAndPassword(username, password).then(user => {
          user.user.getIdTokenResult(true).then((idTokenResult) => {
            //console.log(idTokenResult.token);
            this.setToken(idTokenResult.token);
          });
        });

        return user['data'];
      }));
  }

  loginByLineID(lineID : string) {
    let req = { LineId: lineID };
    return this.http.post<User>('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-getLineUser', req)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        encryptStorage.setItem('user', JSON.stringify(user['data']));
        this.userSubject.next(user['data']);

        this.auth.signInWithCustomToken(this.userSubject.value.CustomToken).then(user => {
          user.user.getIdTokenResult(true).then((idTokenResult) => {
            //console.log(idTokenResult.token);
            this.setToken(idTokenResult.token);
          });
        });

        return user['data'];
      }));
  }

  updateUserWithLineID(lineID : string, userID: number) {
    let req = { LineId: lineID, UserId: userID };
    return this.http.post<User>('https://asia-southeast1-tbit-excise.cloudfunctions.net/apiv2-updateLineUser', req)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        encryptStorage.setItem('user', JSON.stringify(user['data']));
        this.userSubject.next(user['data']);

        this.auth.signInWithCustomToken(this.userSubject.value.CustomToken).then(user => {
          user.user.getIdTokenResult(true).then((idTokenResult) => {
            //console.log(idTokenResult.token);
            this.setToken(idTokenResult.token);
          });
        });

        let data: User = new User();
        Object.assign(data, user['data']);

        return data;
      }));
  }

  logout() {
    // remove user from local storage and set current user to null
    encryptStorage.removeItem('user');
    encryptStorage.clear();
    this.userSubject.next(null);
    this.auth.signOut();
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    });
  }

  sessionExpired() {
    // remove user from local storage and set current user to null
    encryptStorage.removeItem('user');
    encryptStorage.clear();
    this.userSubject.next(null);
    this.auth.signOut();
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    });
  }


  getUserAccess() {
    return this.http.get('https://asia-southeast2-tbit-pmo.cloudfunctions.net/user/GetUserAccessMenu?uid=' + this.userValue.Id).pipe(map(data => {
      this.userAccessSubject.next(data['data']);
      //console.log(JSON.stringify(this.userAccessSubject.value));

      return data['data'];
    }));
  }

  public get userAccessValue(): Array<UserAccess> {
    if (this.userAccessSubject) {
      return this.userAccessSubject.value;
    } else {
      return new Array<UserAccess>();
    }
  }

  public checkUserAccess(page: string): UserAccess {
    let result = new UserAccess();
    for (let i = 0; i < this.userAccessValue.length; i++) {
      if (this.userAccessValue[i].SysNameEn === page) {
        result = this.userAccessValue[i];
        break;
      }
    }

    //console.log(JSON.stringify(result));

    return result;
  }

  forgotpass(email) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/auth/resetpassword', email);
  }

  changepass(data) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/auth/changepassword', data);
  }

  register(user) {
    return this.http.post('https://asia-southeast1-tbit-excise.cloudfunctions.net/auth/signup', user);
  }


  getTitleName() {
    return this.http.get('https://asia-southeast1-tbit-excise.cloudfunctions.net/master/titlelist');
  }

  getAgencyType() {
    return this.http.get('https://asia-southeast2-tbit-pmo-tracking.cloudfunctions.net/master/getlistagency');
  }

  // "Id", "Degree"
  getDegreeList() {
    return this.http.get('https://asia-east2-tbit-pmo.cloudfunctions.net/master/getdegreelist');
  }

  // "Id", "IsAcBranchtive"
  getBranchList() {
    return this.http.get('https://asia-east2-tbit-pmo.cloudfunctions.net/master/getbranchlist');
  }

  getAll() {
    return this.http.get<[]>(`/users`);
  }

  getById(id: string) {
    return this.http.get(`/users/${id}`);
  }

  update(id, params) {
    return this.http.put(`$/users/${id}`, params)
      .pipe(map(x => {
        // update stored user if the logged in user updated their own record
        if (id == String(this.userValue.Id)) {
          // update local storage
          const user = { ...this.userValue, ...params };
          encryptStorage.setItem('user', JSON.stringify(user));

          // publish updated user to subscribers
          this.userSubject.next(user);
        }
        return x;
      }));
  }

  delete(id: string) {
    return this.http.delete(`/users/${id}`)
      .pipe(map(x => {
        // auto logout if the logged in user deleted their own record
        if (id == String(this.userValue.Id)) {
          this.logout();
        }
        return x;
      }));
  }
}
